/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/com.webp'
import IMG2 from '../../assets/TO_DO.webp'
import IMG3 from '../../assets/Sales_Tracker.webp'
import IMG4 from '../../assets/lottery.webp'
import IMG5 from '../../assets/foot.webp'
import IMG6 from '../../assets/crypto.webp'
import IMG7 from '../../assets/voice.gif'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Computer Warehouse Managment System',
    github: 'https://github.com/ljunior23/Computer___warehouse-sys',
    demo: 'https://dribbble.com/shots/14410296-Delivery-App'
  },

  {
    id: 2,
    image: IMG2,
    title: 'TO-DO List app',
    github: 'https://github.com/ljunior23/ToDo-List-app',
    demo: 'https://github.com/ljunior23/ToDo-List-app'
  },
  
  {
    id: 3,
    image: IMG3,
    title: 'Sales Tracker',
    github: 'https://github.com/ljunior23/sales_tracker',
    demo: 'https://www.figma.com/proto/R4adI4P7OJW1ImMKfXtWvT/Sales_Tracker?page-id=0%3A1&node-id=8%3A60&scaling=scale-down'
  },
  {
    id: 4,
    image: IMG4,
    title: 'Daily South African Lottery Result',
    github: 'https://github.com/ljunior23/scrapper-app',
    demo: 'https://dribbble.com/shots/18070485-Money-Manager-Expense-Manager-Dashboard'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Top-20 Football League Standing',
    github: 'https://github.com/ljunior23/Top-10_League_Standings',
    demo: 'https://dribbble.com/shots/18070485-Money-Manager-Expense-Manager-Dashboard'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Top 10 CryptoCurrency Price Feed',
    github: 'https://github.com/ljunior23/price_feed',
    demo: 'https://dribbble.com/shots/18070485-Money-Manager-Expense-Manager-Dashboard'
  },
  {
    id: 7,
    image: IMG7,
    title: 'Voice Recognition',
    github: 'https://github.com/ljunior23/voice_recognition',
    demo: 'https://dribbble.com/shots/18070485-Money-Manager-Expense-Manager-Dashboard'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio___container">
       {
        data.map(({id, image, title, github, demo})=> {
          return(
             <article key={id} className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={image} alt={title} />
          </div>
          <h3>{title}</h3>
          <div className="porfolio__item-cta">
            <a href={github} className='btn' target='_blank' >Github</a>
            {/*<a href={demo}
          className='btn btn-primary' target='_blank' aria-disabled>Live Demo</a>*/}
          </div>
        </article>
          )
        })
       }
      </div>
    </section>
  )
}

export default Portfolio