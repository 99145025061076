/* eslint-disable react-hooks/rules-of-hooks */
import React, {useRef } from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import emailjs from '@emailjs/browser'

const contact = () => {
  const form = useRef();

   const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qlravfe', 'template_ma5pjjy', form.current, 'm1PJbDhxohcWBNiP-')
      
    e.target.reset()
    
  };
  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>kwameleon21@gmail.com</h5>
            <a href="mailto:kwameleon21@gamil.com" target='_blank' rel="noreferrer">Send a message</a>
          </article>
           <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>kwame Leon</h5>
            <a href="https://m.me/kwame.leon" target='_blank' rel="noreferrer">Send a message</a>
          </article>
           <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>+491785036840</h5>
            <a href="https://api.whatsapp.com/send?phnoe+491785036840" target='_blank' rel="noreferrer">Send a message</a>
          </article>
        </div>
        {/*END OF CONTACT OPTION*/}
        <form ref={form} onSubmit= {sendEmail}>
          <input type="text"  name='name' placeholder='FullName' required/>
          <input type="email" name='email' placeholder='Email' required/>
          <textarea name="message" rows="7" placeholder='Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default contact