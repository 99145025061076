import React from 'react'
import './experience.css'
import {BsFillPatchCheckFill} from 'react-icons/bs'

const experience = () => {
  return (
    <section id='experience'>
      <h5>Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>HTML</h4>
                  <small className='text-light'>Advanced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>CSS</h4>
                  <small className='text-light'>Advanced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>JavaScript</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>Bootstrap</h4>
                  <small className='text-light'>Advanced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>React</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>C#</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
          </div>
        </div>
        <div className="experience__backend">
           <h3>Backend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>Java</h4>
                  <small className='text-light'>Conpetent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>PHP</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>C++</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>MySQL</h4>
                  <small className='text-light'>Advanced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>MongoDB</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>Node.JS</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons' />
                <div>
                  <h4>.Net</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Container</h3>
          <div className="experience__content">
              <article className='experience__details'>
                <BsFillPatchCheckFill className='exp-icons'/>
                <div>
                  <h4>Docker</h4>
                  <small className='text-light'>Competent</small>
                </div>
              </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default experience