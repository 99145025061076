/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const headerSocial = () => {
  return (
    <div className="header__socials">
            <a href="https://www.linkedin.com/in/george-kumi-acheampong-604a821b5/" target='_blank'><BsLinkedin/></a>
        <a href="https://github.com/ljunior23" target='_blank'><FaGithub/></a>
    </div>
  )
}

export default headerSocial